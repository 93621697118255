import React from 'react'
import Policy from './Policy'
import { policies } from '../../../config/constants'

const PrivacyPolicy = () => {
  return (
     <Policy policyId={policies?.privacyPolicy}/>
  )
}

export default PrivacyPolicy
