import React, { useState } from 'react'
import {
    Container,
    Button,
    Box,
    Grid2,
    IconButton,
    Drawer,
    useMediaQuery,
    List, ListItem,
    Link,
} from "@mui/material";
import Logo from "../../../assets/images/humanizerylogo.svg";
import MenuIcon from "@mui/icons-material/Menu";
import "./header.css"

// TODO make constant file
const menuItems = [
    {
        name: "Home",
        path: '#home'
    },
    {
        name: "About",
        path: '#about'
    },
    {
        name: "Pricing",
        path: '#pricing'
    },
    {
        name: "FAQ",
        path: '#faq'
    }
];

const Header = ({ activeIndex, setActiveIndex }) => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    // const [activeIndex, setActiveIndex] = useState(0);

    const handleClick = (index, event) => {
        event.preventDefault();
        setActiveIndex(index);
        const section = document.querySelector(menuItems[index].path);
        if (menuItems[index]?.path === "#home") {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        else {
            section?.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const drawerList = () => (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List className='headerMobileMenu'>
                {menuItems.map((text, index) => (
                    <ListItem button key={index} >
                        <Link sx={{
                            textDecoration: "none",
                            color: "#adadad",
                        }} href={text?.path} key={index} className={activeIndex === index ? 'active' : ''}
                            onClick={(event) => handleClick(index, event)}>
                            {text?.name}
                        </Link>
                    </ListItem>
                ))}
            </List>
            <Box
                className="headerMenuRight"
                sx={{ padding: "0 16px", display: "flex", flexDirection: "column" }}
            >
                <Button className="signButton">Sign up</Button>
                <Button className="loginButton">Login</Button>
            </Box>
        </Box>
    );

    const toggleDrawer = (open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setIsDrawerOpen(open);
    };

    return (
        <>   <Box className="header">
            <Container className="customContainer">
                <Grid2 container spacing={2} className="headerContent">
                    <Grid2 size={{ xs: 3, md: 3 }}>
                        <Box sx={{ cursor: "pointer", display: 'inline-block' }} onClick={() => {
                            setActiveIndex(0);
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}>
                            <img src={Logo} alt="Logo" />
                        </Box>
                    </Grid2>

                    {/* Conditional rendering based on screen size */}
                    {isMobile ? (
                        <Grid2 xs={12} md={9}>
                            <Box sx={{ textAlign: "right" }}>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    onClick={toggleDrawer(true)}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Box>
                            <Drawer
                                anchor="right"
                                open={isDrawerOpen}
                                onClose={toggleDrawer(false)}
                            >
                                {drawerList()}
                            </Drawer>
                        </Grid2>
                    ) : (
                        <>
                            <Grid2 size={{ xs: 6, md: 6 }}>
                                <Box className="headerMenu">
                                    {menuItems.map((item, index) => (
                                        <Link href={item.path} key={index} className={activeIndex === index ? 'active' : ''}
                                            onClick={(event) => handleClick(index, event)}>
                                            {item.name}
                                        </Link>
                                    ))}
                                </Box>
                            </Grid2>
                            <Grid2 size={{ xs: 3, md: 3 }}>
                                <Box className="headerMenuRight">
                                    <Button className="signButton">Sign up</Button>
                                    <Button className="loginButton">Login</Button>
                                </Box>
                            </Grid2>
                        </>
                    )}
                </Grid2>
            </Container>
        </Box>
        </>
    )
}

export default Header;


