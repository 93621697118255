import React, { useEffect } from "react";

const Policy = ({policyId}) => {

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://app.termly.io/embed-policy.min.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);
 
    return (
        <div
            id={`policy-${policyId}`}
            name="termly-embed"
            data-id={policyId}
            data-type="iframe"
        ></div>
    );
}

export default Policy