import React from "react";
import {
    Container,
    Typography,
    Box,
    Grid2,
} from "@mui/material";
import Humanicon01 from "../../../assets/icons/humanicon01.svg";
import Humanicon02 from "../../../assets/icons/humanicon02.svg";
import Humanicon03 from "../../../assets/icons/humanicon03.svg";
import Humanicon04 from "../../../assets/icons/humanicon04.svg";
import Humanicon05 from "../../../assets/icons/humanicon05.svg";
import Humanicon06 from "../../../assets/icons/humanicon06.svg";
import HumanleftPattern from "../../../assets/images/humanLeftPatterns.svg";
import HumanrightPattern from "../../../assets/images/humanRightPatterns.svg";
import Humanleft from "../../../assets/images/humanLeft.svg";
import HumanRight from "../../../assets/images/humanRight.svg";

const HumanSection = () => {
    return (
        <Box className="humanSection">
            <Box className="humanleftPattern">
                <img src={HumanleftPattern} alt="HumanleftPattern" />
            </Box>
            <Container className="customContainer">
                <Grid2 container spacing={2} alignItems="center">
                    <Grid2 size={{ xs: 12, md: 12 }}>
                        <Box className="humanSectionTop">
                            <Typography variant="h2">
                                <Box className="humanLeft">
                                    <img src={Humanleft} alt="Humanleft" />
                                </Box>
                                Humanize AI content,
                                <br /> Get 100% human content
                            </Typography>
                            <Typography>
                                Human writing drives higher social media engagement and is more shareable by producing human like content. Utilizing advanced algorithms, it generates human like text that closely resembles authentic human writing. It reduces reliance on writers and editors, offering cost savings while maintaining authenticity.
                                <Box className="humanRight">
                                    <img src={HumanRight} alt="HumanRight" />
                                </Box>
                            </Typography>
                        </Box>
                    </Grid2>
                </Grid2>
            </Container>
            <Container className="humaniconsection">
                <Grid2
                    container
                    spacing={2}
                    alignItems="center"
                    className="humanContent"
                >
                    <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                        <Box className="humanIcon">
                            <img src={Humanicon01} alt="ImproveQuality" />
                            <Typography>
                                Improves Content
                                <br /> Quality
                            </Typography>
                        </Box>
                    </Grid2>
                    <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                        <Box className="humanIcon">
                            <img src={Humanicon02} alt="Detection" />
                            <Typography>
                                Bypasses AI
                                <br /> Detection
                            </Typography>
                        </Box>
                    </Grid2>
                    <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                        <Box className="humanIcon">
                            <img src={Humanicon03} alt="Undetectable" />
                            <Typography>
                                Ensuring it’s polished and
                                <br /> undetectable.
                            </Typography>
                        </Box>
                    </Grid2>
                    <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                        <Box className="humanIcon">
                            <img src={Humanicon04} alt="Rankings & Credibility" />
                            <Typography>
                                Improve website
                                <br /> rankings and credibility
                            </Typography>
                        </Box>
                    </Grid2>
                    <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                        <Box className="humanIcon">
                            <img src={Humanicon05} alt="Compatibility" />
                            <Typography>
                                Enhance search engine
                                <br /> compatibility
                            </Typography>
                        </Box>
                    </Grid2>
                    <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                        <Box className="humanIcon">
                            <img src={Humanicon06} alt="ClearContent" />
                            <Typography>
                                Create more engaging, <br /> relatable, and clear content
                            </Typography>
                        </Box>
                    </Grid2>
                </Grid2>
            </Container>
            <Box className="humanrightPattern">
                <img src={HumanrightPattern} alt="HumanrightPattern" />
            </Box>
        </Box>
    )
}

export default HumanSection