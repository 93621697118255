import React from 'react'
import Policy from './Policy'
import { policies } from '../../../config/constants'

const CookiePolicy = () => {
  return (
   <Policy policyId={policies?.cookiePolicy} />
  )
}

export default CookiePolicy
