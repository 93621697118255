// src/App.js
import React, { useEffect } from 'react';
import 'typeface-inter';
import Home from "./ui/pages/Home/Home"

function App() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])

  return (
    <>
      <Home />
    </>
  );
}

export default App;
