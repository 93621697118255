import React, { useEffect, useRef, useState } from "react";
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import AIbannerSection from "../../components/home/AIbannerSection";
import ExpertSection from "../../components/home/ExpertSection";
import BypassSection from "../../components/home/BypassSection";
import ChallegeSection from "../../components/home/ChallegeSection";
import HumanSection from "../../components/home/HumanSection";
import PoweredSection from "../../components/home/PoweredSection";
import PlanSection from "../../components/home/PlanSection";
import LanguageSection from "../../components/home/LanguageSection";
import FaqSection from "../../components/home/FaqSection";
import UpcomingSection from "../../components/home/UpcomingSection";
import { Box } from "@mui/material";
import ReactDOMServer from 'react-dom/server';
import { Quill } from 'react-quill';
import "./home.css";

const icons = Quill.import('ui/icons');

const CustomUndo = () => (
  <svg width="13" height="auto" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M7.11119 3.25606H2.414L3.79675 1.8737L3.2563 1.32861L0.943359 3.64155L3.2563 5.95449L3.79675 5.40902L2.41516 4.02704H7.11119C7.72462 4.02704 8.31293 4.27072 8.74668 4.70448C9.18044 5.13824 9.42413 5.72655 9.42413 6.33998C9.42413 6.95341 9.18044 7.54171 8.74668 7.97547C8.31293 8.40923 7.72462 8.65291 7.11119 8.65291H4.02728V9.42389H7.11119C7.9291 9.42389 8.7135 9.09898 9.29185 8.52064C9.8702 7.94229 10.1951 7.15788 10.1951 6.33998C10.1951 5.52207 9.8702 4.73767 9.29185 4.15932C8.7135 3.58097 7.9291 3.25606 7.11119 3.25606Z" fill="#444444" stroke="#444444" stroke-width="0.8" /> </svg>
);

const CustomRedo = () => (
  <svg width="13" height="auto" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M3.53118 3.25606H8.22837L6.84562 1.8737L7.38608 1.32861L9.69901 3.64155L7.38608 5.95449L6.84562 5.40902L8.22721 4.02704H3.53118C2.91775 4.02704 2.32945 4.27072 1.89569 4.70448C1.46193 5.13824 1.21824 5.72655 1.21824 6.33998C1.21824 6.95341 1.46193 7.54171 1.89569 7.97547C2.32945 8.40923 2.91775 8.65291 3.53118 8.65291H6.6151V9.42389H3.53118C2.71328 9.42389 1.92887 9.09898 1.35052 8.52064C0.772177 7.94229 0.447266 7.15788 0.447266 6.33998C0.447266 5.52207 0.772177 4.73767 1.35052 4.15932C1.92887 3.58097 2.71328 3.25606 3.53118 3.25606V3.25606Z" fill="#444444" stroke="#444444" stroke-width="0.8" /> </svg>
);

const CustomSource = () => (
  <svg width="19" height="auto" viewBox="0 0 32 16" fill="#444" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.335569 8.80687C-0.111856 8.36059 -0.111856 7.63584 0.335569 7.18956L7.20801 0.33471C7.65544 -0.111569 8.38206 -0.111569 8.82948 0.33471C9.27691 0.780989 9.27691 1.50575 8.82948 1.95203L2.76598 8L8.8259 14.048C9.27333 14.4943 9.27333 15.219 8.8259 15.6653C8.37848 16.1116 7.65186 16.1116 7.20443 15.6653L0.331989 8.81044L0.335569 8.80687Z" fill="black" />
    <path d="M30.8059 7.19313C31.2535 7.63941 31.2535 8.36416 30.8059 8.81044L23.9297 15.6653C23.482 16.1116 22.755 16.1116 22.3074 15.6653C21.8597 15.219 21.8597 14.4943 22.3074 14.048L28.3741 8L22.3109 1.95202C21.8633 1.50575 21.8633 0.780988 22.3109 0.334709C22.7586 -0.11157 23.4856 -0.11157 23.9333 0.334709L30.8094 7.18956L30.8059 7.19313Z" fill="black" />
  </svg>
)

const CustomDivider = () => (
  <svg width="13" height="auto" fill="#444" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" /></svg>
)

icons['undo'] = ReactDOMServer.renderToString(<CustomUndo />);
icons['redo'] = ReactDOMServer.renderToString(<CustomRedo />);
icons['source'] = ReactDOMServer.renderToString(<CustomSource />);
icons['divider'] = ReactDOMServer.renderToString(<CustomDivider />);

const Home = () => {

  const [activeIndex, setActiveIndex] = useState(0);

  // useEffect(() => {
  //   const sections = document.querySelectorAll("div[id]");

  //   const navHighlighter = () => {
  //     const scrollY = window.pageYOffset;

  //     sections.forEach(current => {
  //       const sectionHeight = current.offsetHeight;
  //       const sectionTop = current.offsetTop - 50;
  //       const sectionId = current.getAttribute("id");

  //       const navLink = document.querySelector(`a[href*="${sectionId}"]`);

  //       if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
  //         navLink?.classList.add("active");
  //         // console.log(navLink);
  //         // setActiveIndex(null)
  //         // if (!navLink?.href) {
  //         //   setActiveIndex(0)
  //         // }
  //         // else {
  //         //   if (navLink?.href === "http://localhost:3000/#about") {
  //         //     setActiveIndex(1)
  //         //   }
  //         //   if (navLink?.href === "http://localhost:3000/#pricing") {
  //         //     setActiveIndex(2)
  //         //   }
  //         //   if (navLink?.href === "http://localhost:3000/#faq") {
  //         //     setActiveIndex(3)
  //         //   }
  //         // }
  //         // console.log("navLink?.href", navLink?.href);
  //       } else {
  //         navLink?.classList.remove("active");
  //       }
  //     });
  //   };

  //   window.addEventListener("scroll", navHighlighter);

  //   // Cleanup event listener on component unmount
  //   return () => {
  //     window.removeEventListener("scroll", navHighlighter);
  //   };
  // }, []);


  return (
    <>
      {/* <Box id="home"> */}
      <Header activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
      <AIbannerSection />
      {/* </Box> */}
      <Box id="about">
        <ExpertSection />
        <BypassSection />
        <ChallegeSection />
        <HumanSection />
        <PoweredSection />
      </Box>
      <Box id="pricing">
        <PlanSection />
      </Box>
      <LanguageSection />
      <Box id="faq">
        <FaqSection />
      </Box>
      <UpcomingSection />
      <Footer setActiveIndex={setActiveIndex} />
    </>
  );
};

export default Home;



