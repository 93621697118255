import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const countries = [
    { code: 'IN', label: 'Hindi' },
    { code: 'ES', label: 'Spanish' },
]

const LanguageSelector = () => {
    return (
        <Autocomplete size='small'
          id="country-select-demo"
          sx={{ width: 120 }}
          options={countries}
          autoHighlight
          getOptionLabel={(option) => option.label}
          renderOption={(props, option) => {
            const { key, ...optionProps } = props;
            return (
              <Box
                key={key}
                component="li"
                sx={{ '& > img': { mr: 2, flexShrink: 0 }, fontSize:"14px" }}
                {...optionProps}
              >
                <img
                  loading="lazy"
                  width="20"
                  srcSet={`https://flagcdn.com/w40/${option?.code?.toLowerCase()}.png 2x`}
                  src={`https://flagcdn.com/w20/${option?.code?.toLowerCase()}.png`}
                  alt=""
                />
                {option.label}
              </Box>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="English"
              sx={{'& > label':{fontSize:'15px !important'}}}
              slotProps={{
                htmlInput: {
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                },
              }}
            />
          )}
        />
      );
}

export default LanguageSelector
