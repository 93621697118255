import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const Notification = ({ notificationOpen, setNotificationOpen, notificationMessage }) => {

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setNotificationOpen(false);
    };

    return (
        <Snackbar open={notificationOpen} autoHideDuration={5000} onClose={handleClose}>
            <Alert
                onClose={handleClose}
                severity={notificationMessage === "Humanize successfully" ? "success" : 'error'}
                variant="filled"
                sx={{ width: '100%' }}
            >
                {notificationMessage}
            </Alert>
        </Snackbar>
    )
}

export default Notification
