import React from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  Grid2,
} from "@mui/material";

import ArrowRight from "../../../assets/icons/rightarrow.svg";
import Afterpricingplan from "../../../assets/images/afterpricingplan.svg";


const UpcomingSection = () => {
    return (
        <Box className="upcomingSection">
            <Container className="customContainer">
                <Grid2 container spacing={{md : 7, xs: 3}} alignItems="center">
                    <Grid2 size={{ xs: 12, md: 4 }}>
                        <Box className="upcomingSectionLeft">
                            <Typography variant="h2">Upcoming AI tools</Typography>
                            <Typography>
                                Explore the other powerful AI tools from the Humanizery free
                                tool suite
                            </Typography>
                        </Box>
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 8 }} sx={{ position: "relative" }}>
                        <Box className="upcomingSectionbefore">
                            <img src={Afterpricingplan} alt="" />
                        </Box>
                        <Box className="upcomingSectionRight">
                            <Box className="upcomingAiCard">
                                <Typography variant="h5">AI Inter-linker</Typography>
                                <Typography>
                                    AI Internal-Linker automatically creates relevant internal
                                    links, enhancing site navigation and SEO.
                                </Typography>
                                <Button>
                                    Learn more
                                    <Box
                                        sx={{
                                            lineHeight: "0",
                                        }}
                                    >
                                        <img src={ArrowRight} alt="" />
                                    </Box>
                                </Button>
                            </Box>
                            <Box className="upcomingAiCard">
                                <Typography variant="h5">AI WP Scheduler</Typography>
                                <Typography>
                                    AI WP Scheduler automates WordPress content scheduling,
                                    streamlining your publishing process and saving time.
                                </Typography>
                                <Button>
                                    Learn more
                                    <Box
                                        sx={{
                                            lineHeight: "0",
                                        }}
                                    >
                                        <img src={ArrowRight} alt="" />
                                    </Box>
                                </Button>
                            </Box>
                            <Box className="upcomingAiCard">
                                <Typography variant="h5">Integrations</Typography>
                                <Typography>
                                    Integrations connect tools to streamline workflows and boost
                                    productivity.
                                </Typography>
                                <Button>
                                    Learn more
                                    <Box
                                        sx={{
                                            lineHeight: "0",
                                        }}
                                    >
                                        <img src={ArrowRight} alt="" />
                                    </Box>
                                </Button>
                            </Box>
                            <Box className="upcomingAiCard">
                                <Typography variant="h5">API</Typography>
                                <Typography>
                                    An API AI tool integrates AI features into apps for enhanced
                                    functionality.
                                </Typography>
                                <Button>
                                    Learn more
                                    <Box
                                        sx={{
                                            lineHeight: "0",
                                        }}
                                    >
                                        <img src={ArrowRight} alt="" />
                                    </Box>
                                </Button>
                            </Box>
                        </Box>
                        <Box className="upcomingSectionafter">
                            <img src={Afterpricingplan} alt="" />
                        </Box>
                    </Grid2>
                </Grid2>
            </Container>
        </Box>
    )
}

export default UpcomingSection