import React from "react";
import {
    Container,
    Typography,
    Button,
    Box,
    Grid2,
} from "@mui/material";
import Logo from "../../../assets/images/humanizerylogo.svg";
import Facebook from "../../../assets/icons/facebook.svg";
import TelegramIcon from "../../../assets/icons/telegram.svg";
import GitIcon from "../../../assets/icons/git.svg";
import InstagramIcon from "../../../assets/icons/instagram.svg";
import Catching from "../../../assets/icons/cat.svg";
import "./footer.css";

const Footer = ({ setActiveIndex }) => {

    const handleClick = (sectionId) => {
        if (sectionId === "#home") {
            setActiveIndex(0);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        if (sectionId === "#about") {
            setActiveIndex(1);
        }
        if (sectionId === "#faq") {
            setActiveIndex(3);
        }
        const section = document.querySelector(sectionId);
        section?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>
            <Box className="footerSection">
                <Box className="footerTop">
                    <Container className="customContainer">
                        <Grid2 container spacing={2} alignItems="center">
                            <Grid2 size={{ xs: 12, md: 12 }}>
                                <Button
                                    sx={{
                                        padding: "0",
                                        "& img": {
                                            maxWidth: "74px",
                                            width: "100%",
                                        },
                                    }}
                                    onClick={() => handleClick('#home')}
                                >
                                    <img src={Logo} alt="Logo" />
                                </Button>
                            </Grid2>
                        </Grid2>
                    </Container>
                </Box>
                <Box className="footerMiddle">
                    <Container className="customContainer">
                        <Grid2 container spacing={2} alignItems="flex-start">
                            <Grid2 size={{ xs: 6, sm: 6, md: 6, lg: 3 }}>
                                <Box className="footerColumn">
                                    <Typography variant="h5">Product</Typography>
                                    <Box className="footerColumnList">
                                        <Typography>AI Humanizer</Typography>
                                        <Typography>AI Inter-linker</Typography>
                                        <Typography>AI WP Scheduler</Typography>
                                        <Typography>Integrations</Typography>
                                        <Typography>API</Typography>
                                    </Box>
                                </Box>
                            </Grid2>
                            <Grid2 size={{ xs: 6, sm: 6, md: 6, lg: 3 }}>
                                <Box className="footerColumn">
                                    <Typography variant="h5">Use Cases</Typography>
                                    <Box className="footerColumnList">
                                        <Typography component='a' href="https://blog.ai2humanize.com/Web-designers-119b509b4e1a808cabfaf87434eaea65?pvs=4" target="_blank">Web-designers</Typography>
                                        <Typography component='a' href="https://blog.ai2humanize.com/HR-Professionals-119b509b4e1a800eab77f017588ea6b2" target="_blank">HR Professionals</Typography>
                                        <Typography component='a' href="https://blog.ai2humanize.com/Small-Business-119b509b4e1a8038b7c5d2ca74e698d9" target="_blank">Small Business</Typography>
                                        <Typography component='a' href="https://blog.ai2humanize.com/Website-Builder-119b509b4e1a80cb9b7af0b38a1bf210" target="_blank">Website Builder</Typography>
                                    </Box>
                                </Box>
                            </Grid2>
                            <Grid2 size={{ xs: 6, sm: 6, md: 6, lg: 4 }}>
                                <Box className="footerColumn">
                                    <Typography variant="h5">Use Cases</Typography>
                                    <Box className="footerColumnList">
                                        <Typography component='a' href="https://blog.ai2humanize.com/The-Use-Case-of-AI-Humanizer-for-PR-Professionals-117b509b4e1a809784e9c1917a9f39bc" target="_blank">PR Professionals</Typography>
                                        <Typography component='a' href="https://blog.ai2humanize.com/Social-Media-Marketers-117b509b4e1a809784e9c1917a9f39bc" target="_blank">Social Media Marketers</Typography>
                                        <Typography component='a' href="https://blog.ai2humanize.com/Bloggers-117b509b4e1a809784e9c1917a9f39bc" target="_blank">Bloggers</Typography>
                                        <Typography component='a' href="https://blog.ai2humanize.com/Freelance-Writers-119b509b4e1a8056bbc0d2725bf01765" target="_blank">Freelance Writers</Typography>
                                    </Box>
                                </Box>
                            </Grid2>
                            <Grid2 size={{ xs: 6, sm: 6, md: 6, lg: 2 }}>
                                <Box className="footerColumn">
                                    <Typography variant="h5">Company</Typography>
                                    <Box className="footerColumnList">
                                        <Typography component='a' href="https://blog.ai2humanize.com/About-Us-117b509b4e1a80baa2fcc7b69932d2ea" target="_blank">About Us</Typography>
                                        <Typography component='a' href="https://blog.ai2humanize.com/Careers-117b509b4e1a80ee8c1ef7ba6844c247" target="_blank">Careers</Typography>
                                        <Typography onClick={() => handleClick('#faq')}>FAQs</Typography>
                                        <Typography>Contact Us</Typography>
                                    </Box>
                                </Box>
                            </Grid2>
                        </Grid2>
                    </Container>
                </Box>
                <Box className="footerBottom">
                    <Container className="customContainer">
                        <Grid2 container spacing={2} alignItems="center">
                            <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 6.5 }}>
                                <Box className="footerBottomlink">
                                    <Typography onClick={() => {
                                        window.open('/privacy-policy')
                                    }}>Privacy Policy
                                    </Typography>
                                    <Typography onClick={() => {
                                        window.open('/cookie-policy')
                                    }}>Cookie Policy</Typography>
                                    <Typography onClick={() => {
                                        window.open('/terms-conditions')
                                    }}>Terms of Use</Typography>
                                    <Typography onClick={() => {
                                        window.open('/sales-refund')
                                    }}>Sales and Refunds</Typography>
                                    <Typography onClick={() => {
                                        window.open('/legal')
                                    }}>Legal</Typography>
                                    <Typography>Site Map</Typography>
                                </Box>
                            </Grid2>
                            <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 3 }}>
                                <Box className="footerBottomSocial">
                                    <Button>
                                        <img src={Facebook} alt="Facebook" />
                                    </Button>
                                    <Button>
                                        <img src={TelegramIcon} alt="Telegram" />
                                    </Button>
                                    <Button>
                                        <img src={GitIcon} alt="Git" />
                                    </Button>
                                    <Button>
                                        <img src={InstagramIcon} alt="Instagram" />
                                    </Button>
                                    <Button>
                                        <img src={Catching} alt="Catching" />
                                    </Button>
                                </Box>
                            </Grid2>
                            <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 2.5 }}>
                                <Box className="footerBottomcopyright">
                                    <Typography>© 2024 All Rights Reserved</Typography>
                                </Box>
                            </Grid2>
                        </Grid2>
                    </Container>
                </Box>
            </Box>
            {/* footer end*/}
        </>
    );
};

export default Footer;
