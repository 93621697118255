import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'

import ToastReducer from './Reducers/ToastReducers'
import rootSagas from './Sagas/rootSagas'
// import reducer from './reducers'
// import mySaga from './sagas'

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()
// mount it on the Store

const rootReducers = {
    Toastify: ToastReducer
}

const store = configureStore({
    reducer: rootReducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
})

// then run the saga
sagaMiddleware.run(rootSagas)

export default store;

// render the application