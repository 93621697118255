import React, { useEffect, useMemo, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import SourceModal from '../../modals/SourceModal';
import { Box, IconButton } from '@mui/material';
// import SendIcon from '@mui/icons-material/Send';
import expand from '../../../assets/images/expand.svg';
import deleteicon from '../../../assets/icons/delete.svg';
import LanguageSelector from '../languageSelector/LanguageSelector';
import ConfirmDialog from '../../modals/ConfirmDialog';
import 'quill/dist/quill.snow.css';

// Create a custom blot for the divider
const DividerBlot = Quill.import('blots/block');

class Divider extends DividerBlot {
    static create() {
        let node = super.create();
        node.classList.add('ql-divider');
        return node;
    }

    static formats(node) {
        return true;
    }

    format(name, value) {
        if (name === 'divider') {
            this.domNode.classList.toggle('ql-divider', value);
        } else {
            super.format(name, value);
        }
    }
}

Divider.blotName = 'divider';
Divider.tagName = 'hr';

Quill.register(Divider);

const toolbarOptions = [
    ["undo", "redo"],
    [{ size: ["small", false, "large", "huge"] }],
    [{ align: [] }],
    [{ color: [] }],
    ["bold", "italic", "underline", "strike", "source"],
    [{ list: "bullet" }, { list: "ordered" }],
    ["link", "image", "code-block", "blockquote", "divider"]
];

const formats = [
    "header", "size", "align", "color",
    "bold", "italic", "underline", "strike",
    "blockquote", "list", "bullet", "indent",
    "link", "image", "code-block", "divider",
    "source"
];

const UserEditorSection = ({ textEditorContent, setTextEditorContent, quillRef, handleOpen }) => {

    const [modalOpen, setModalOpen] = useState(false);
    // const [editorContent, setTextEditorContent] = useState('');
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    const handleConfirmDialogClose = () => {
        setConfirmDialogOpen(false);
    };

    // useEffect(() => {
    //     setTextEditorContent(textEditorContent)
    // }, [textEditorContent])

    const toggle = () => setModalOpen(!modalOpen)

    const handleModalSave = (content) => {

        // const quill = quillRef.current.getEditor();
        // quill.root.innerHTML = content; // Update editor content with modal content
        setModalOpen(false); // Close the modal
    };

    const modules = useMemo(() => ({
        toolbar: {
            container: toolbarOptions,
            handlers: {
                'source': () => {
                    const quill = quillRef.current?.getEditor();
                    toggle();
                    setTextEditorContent(quill.root.innerHTML);
                },
                'divider': () => {
                    const quill = quillRef.current?.getEditor();
                    const range = quill.getSelection();
                    if (range) {
                        quill.insertEmbed(range.index, 'divider', true);
                    }
                },
                'undo': () => {
                    const quill = quillRef.current?.getEditor();
                    if (quill?.history) {
                        quill.history.undo();
                    }
                },
                'redo': () => {
                    const quill = quillRef.current?.getEditor();
                    if (quill?.history) {
                        quill.history.redo();
                    }
                }
            }
        },
        history: {
            delay: 1000,
            maxStack: 100,
            userOnly: true
        }
    }), [toolbarOptions, quillRef]);

    const editor = useMemo(() => <ReactQuill
        ref={quillRef}
        theme="snow"
        value={textEditorContent}
        modules={modules}
        formats={formats}
        placeholder="Enter your text you want to humanize"
        onChange={(content) => setTextEditorContent(content)}
        id='user-editor'
        className='reactquill'
    />, [textEditorContent, toolbarOptions, quillRef])

    return (
        <Box>
            <Box>
                {editor}
                <SourceModal
                    open={modalOpen}
                    toggle={toggle}
                    handleSave={handleModalSave}
                    content={textEditorContent}
                    setTextEditorContent={setTextEditorContent}
                />
            </Box>
            <Box className='editor-btn'>
                {/* <Box>   
                    <IconButton aria-label="send" className='editor-expand' onClick={handleOpen} >
                        <img src={expand} alt="expand" />
                    </IconButton>
                </Box> */}
                <Box className="editerButton">
                    <IconButton aria-label="send" className='editor-expand'>
                        <img src={expand} alt="expand" />
                    </IconButton>
                    {/* <IconButton aria-label="delete" className='editor-deleteicon' onClick={() => setConfirmDialogOpen(true)}>
                        <img src={deleteicon} alt="delete" />
                    </IconButton> */}
                </Box>
                <Box className='language-selector'>
                    <LanguageSelector />
                </Box>
            </Box>
            <ConfirmDialog
                setTextEditorContent={setTextEditorContent}
                confirmDialogOpen={confirmDialogOpen}
                handleConfirmDialogClose={handleConfirmDialogClose}
            />
        </Box>
    );
}

export default UserEditorSection