import React from 'react'
import Policy from './Policy'
import { policies } from '../../../config/constants'

const TermsCondition = () => {
  return (
      <Policy policyId={policies?.termsCondition}/>
  )
}

export default TermsCondition
