import React from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  Grid2,
} from "@mui/material";

import Google from "../../../assets/images/Google.svg";
import Bing from "../../../assets/images/Bing.svg";
import Fiverr from "../../../assets/images/Fiverr.svg";
import Upwork from "../../../assets/images/Upwork.svg";
import Quora from "../../../assets/images/Quora.svg";
import Yandex from "../../../assets/images/Yandex.svg";
import Bai from "../../../assets/images/Bai.svg";
import Ask from "../../../assets/images/Ask.svg";
import Ecosia from "../../../assets/images/Ecosia.svg";

const ExpertSection = () => {
    return (
        <Box className="expertSection">
            <Container className="customContainer">
                <Grid2 container spacing={2} alignItems="center">
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <Typography variant="h2">Trusted by Experts & Users</Typography>
                        <Typography>
                            As user it is important to have positive experience while using
                            the website
                        </Typography>
                        <Button className="getStart" variant="contained">
                            Get Started
                        </Button>
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <Box className="brandWrap">
                            <Button>
                                <img src={Google} alt="Google"/>
                            </Button>
                            <Button>
                                <img src={Bing} alt="Bing" />
                            </Button>
                            <Button>
                                <img src={Fiverr} alt="Fiverr" />
                            </Button>
                            <Button className="marginLeftBrand">
                                <img src={Upwork} alt="Upwork"/>
                            </Button>
                            <Button>
                                <img src={Quora} alt="Quora" />
                            </Button>
                            <Button>
                                <img src={Yandex} alt="Yandex"/>
                            </Button>
                            <Button>
                                <img src={Bai} alt="Bai" />
                            </Button>
                            <Button>
                                <img src={Ask} alt="Ask"/>
                            </Button>
                            <Button>
                                <img src={Ecosia} alt="Ecosia" />
                            </Button>
                        </Box>
                    </Grid2>
                </Grid2>
            </Container>
        </Box>
    )
}

export default ExpertSection