import React from "react";
import {
    Container,
    Typography,
    Box,
    Grid2,
} from "@mui/material";

import Powered01 from "../../../assets/images/powered01.svg";
import Powered02 from "../../../assets/images/powered02.svg";
import Powered03 from "../../../assets/images/powered03.svg";
import Powered04 from "../../../assets/images/powered04.svg";
import Rank from "../../../assets/images/rank.svg";

const PoweredSection = () => {
    return (
        <Box className="poweredSection">
            <Container className="customContainer">
                <Grid2 container spacing={4} alignItems="center">
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <Box className="poweredSectionTop">
                            <Typography variant="h2">
                                Rank #1 with Stealthy AI-Powered Content
                            </Typography>
                            <Typography>
                                Advanced AI enhances content creation by producing text that blends seamlessly with human writing, avoiding detection by search engines.
                            </Typography>
                        </Box>
                        <Box className="poweredSectionBottom">
                            <Typography variant="h6">
                                Is AI-generated content safe to use for SEO?
                            </Typography>
                            <Typography>
                                AI content can be SEO-safe if it’s high-quality, relevant, and follows best practices. Undetectable AI content adheres to search engine guidelines.
                            </Typography>
                        </Box>
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <Box className="poweredLogocontent">
                            <Box className="poweredLogo">
                                <img src={Powered01} alt="" />
                            </Box>
                            <Box className="poweredLogo borderRadiusOdd">
                                <img src={Powered02} alt="" />
                            </Box>
                            <Box className="poweredLogo">
                                <img src={Powered03} alt="" />
                            </Box>
                            <Box className="poweredLogo borderRadiusOdd">
                                <img src={Powered04} alt="" />
                            </Box>
                        </Box>
                        <Box className="poweredRank">
                            <img src={Rank} alt="" />
                        </Box>
                    </Grid2>
                </Grid2>
            </Container>
        </Box>
    )
}

export default PoweredSection