import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Box, Typography } from '@mui/material';
import Originality from '../../../assets/images/Originality 3.0.svg'
import ZeroGPT from '../../../assets/images/ZeroGPT.svg'
import Crossplag from '../../../assets/images/Crossplag.svg'
import ContentAtScale from '../../../assets/images/ContentAtScale.svg'
import Copyleaks from '../../../assets/images/Copyleaks.svg'
import OpenAI from '../../../assets/images/robort.svg'
import GPTZero from '../../../assets/images/GPTZero.svg'
import CheckComplete from '../../../assets/images/CheckComplete.svg'
import Writer from '../../../assets/images/Writer.svg'
import { useState } from 'react';


const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
      border: 'none',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
      border: 'none',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
    border: 'none',
    ...theme.applyStyles('dark', {
      borderColor: theme.palette.grey[800],
    }),
  },
}));

const QontoStepIconRoot = styled('div')(({ theme }) => ({
  color: '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  '& .QontoStepIcon-completedIcon': {
    color: '#fff',
    zIndex: 1,
    fontSize: 18,
    borderRadius: '50%',
    backgroundColor: '#1B8473',
    width: "27px",
    height: "27px",
    boxShadow: " 0 0 0 4px white",
  },
  '& .QontoStepIcon-circle': {
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: '#1B8473',
    margin: '4px'
  },
  '& .QontoStepIcon-circle-container': {
    border: '1px solid #727272',
    borderRadius: '50%',
    backgroundColor: "#fff",
    height: '34px',
    width: '34px',
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: " 0 0 0 4px white",
  },
  '& .QontoStepIcon-circle-empty': {
    border: '1px solid #727272',
    borderRadius: '50%',
    height: '34px',
    width: '34px',
    boxShadow: " 0 0 0 4px white",
    backgroundColor: "#fff"
  },
  ...theme.applyStyles('dark', {
    color: theme.palette.grey[700],
  }),

  variants: [
    {
      props: ({ ownerState }) => ownerState.active,
      style: {
        color: '#784af4',
      },
    },
  ],
}));

function QontoStepIcon({ active, completed, className, setHoverIndex, index }) {
  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <img src={CheckComplete} alt='CheckComplete' onMouseEnter={() => setHoverIndex(index)}  onMouseLeave={() => setHoverIndex(null)} />
        // <Check className="QontoStepIcon-completedIcon" />
      ) : (!active ? <Box className="QontoStepIcon-circle-empty" onMouseEnter={() => setHoverIndex(index)}  onMouseLeave={() => setHoverIndex(null)}>
      </Box> : <Box className="QontoStepIcon-circle-container" onMouseEnter={() => setHoverIndex(index)}  onMouseLeave={() => setHoverIndex(null)}>
        <div className="QontoStepIcon-circle" />
      </Box>
      )}
    </QontoStepIconRoot>
  );
}
QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const steps = [{
  image: Originality,
  label: "Originality 3.0"
}, {
  image: ZeroGPT,
  label: "ZeroGPT"
},
{
  image: Crossplag,
  label: "Crossplag"
},
{
  image: ContentAtScale,
  label: "Content At Scale"
},
{
  image: Copyleaks,
  label: "Copyleaks"
},
{
  image: OpenAI,
  label: "OpenAI"
},
{
  image: GPTZero,
  label: "GPTZero"
},
{
  image: Writer,
  label: "Writer"
},
];

const StepperSection = () => {

  const [hoverIndex, setHoverIndex] = useState(null);
  console.log("hoverIndex", hoverIndex);
  return (
    <>
      <Box className="border_bottom"></Box>
      <Stack sx={{ width: '100%' }} spacing={4} className="custom_steper">
        <Stepper alternativeLabel activeStep={1} connector={<QontoConnector />}>
          {steps.map((stepperData, index) => (
            <Step key={stepperData.label}>
              <StepLabel
                StepIconComponent={(props) => (
                  <QontoStepIcon  {...props} index={index}  hoverIndex={hoverIndex} setHoverIndex={setHoverIndex}  />
                )}
              >
                <Box
                  className="step_label"
                  // onMouseEnter={() => setHoverIndex(index)}
                  // onMouseLeave={() => setHoverIndex(null)}
                >
                  <img src={stepperData.image} alt={stepperData.label} />
                  <Typography sx={{ color: "#1B8473", display: hoverIndex === index ? 'block' : 'none' }}>
                    {stepperData.label}
                  </Typography>
                </Box>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
    </>
  );
}

export default StepperSection