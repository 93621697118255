import { createBrowserRouter } from "react-router-dom";
import Home from "../ui/pages/Home/Home";
import PrivacyPolicy from "../ui/components/policy/PrivacyPolicy";
import TermsCondition from "../ui/components/policy/TermsCondition";
import ReturnPolicy from "../ui/components/policy/ReturnPolicy";
import LegalPolicy from "../ui/components/policy/LegalPolicy";
import CookiePolicy from "../ui/components/policy/CookiePolicy";

const router = createBrowserRouter([
    {
      path: "/",
      element: <Home/>,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy/>,
    },
    {
      path: "/terms-conditions",
      element: <TermsCondition/>,
    },
    {
      path: "/sales-refund",
      element: <ReturnPolicy/>,
    },
    {
      path: "/legal",
      element: <LegalPolicy/>,
    },
    {
      path: "/cookie-policy",
      element: <CookiePolicy/>,
    },
  ]);

export default router
  