import React from 'react'
import Policy from './Policy'
import { policies } from '../../../config/constants'

const LegalPolicy = () => {
  return (
   <Policy policyId={policies?.legalPolicy}/>
  )
}

export default LegalPolicy
