import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import SourceModal from '../../modals/SourceModal';
import { Box, IconButton, Tooltip } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { styled } from '@mui/material/styles';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import html2pdf from 'html2pdf.js';
import Word from '../../../assets/images/word-press.svg'
import Pdf from '../../../assets/images/pdf.svg'
import Document from '../../../assets/images/document.svg'
import Notepad from '../../../assets/images/notepad.svg'
import paste from '../../../assets/images/paste.svg'
import plus from '../../../assets/images/plus.svg'
import 'quill/dist/quill.snow.css';
// Create a custom blot for the divider
const DividerBlot = Quill.import('blots/block');

class Divider extends DividerBlot {
    static create() {
        let node = super.create();
        node.classList.add('ql-divider');
        return node;
    }

    static formats(node) {
        return true;
    }

    format(name, value) {
        if (name === 'divider') {
            this.domNode.classList.toggle('ql-divider', value);
        } else {
            super.format(name, value);
        }
    }
}

Divider.blotName = 'divider';
Divider.tagName = 'hr';

Quill.register(Divider);

const toolbarOptions = [
    ["undo", "redo"],
    [{ size: ["small", false, "large", "huge"] }],
    [{ align: [] }],
    [{ color: [] }],
    ["bold", "italic", "underline", "strike", "source"],
    [{ list: "bullet" }, { list: "ordered" }],
    ["link", "image", "code-block", "blockquote", "divider"]
];

const formats = [
    "header", "size", "align", "color",
    "bold", "italic", "underline", "strike",
    "blockquote", "list", "bullet", "indent",
    "link", "image", "code-block", "divider",
    "source"
];

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
        top: theme.spacing(2),
        left: theme.spacing(2),
    },
}));

const actions = [
    { icon: <img src={Word} alt="word" />, name: 'word File' },
    { icon: <img src={Pdf} alt="pdf" />, name: 'PDF File' },
    { icon: <img src={Document} alt="notepad" />, name: 'Notepad File' },
    { icon: <img src={Notepad} alt="document" />, name: 'Document File' },
];


const AutoAIEditorSection = ({ secondEditorContent }) => {

    const [modalOpen, setModalOpen] = useState(false);
    const [editorContent, setEditorContent] = useState('');
    const [copyStatus, setCopyStatus] = useState(false); // To indicate if the text was copied
    const [copyContent, setCopyContent] = useState('');

    const onCopyText = () => {
        setCopyStatus(true);
        setTimeout(() => setCopyStatus(false), 2000); // Reset status after 2 seconds
    };

    const quillRef = useRef(null);

    useEffect(() => {
        setEditorContent(secondEditorContent);
    }, [secondEditorContent])

    useEffect(() => {
        var plainString = editorContent?.replace(/<[^>]+>/g, '');
        setCopyContent(plainString) 
    },[editorContent])

    const toggle = () => setModalOpen(!modalOpen)

    const handleModalSave = (content) => {
        const quill = quillRef.current.getEditor();
        quill.root.innerHTML = content; // Update editor content with modal content
        setModalOpen(false); // Close the modal
    };

    const convert_HTML_To_Word = () => {
        const header = `<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
            "xmlns:w='urn:schemas-microsoft-com:office:word' " +
            "xmlns='http://www.w3.org/TR/REC-html40'>" +
            "<head><meta charset='utf-8'><title>Export HTML to Word Document with JavaScript</title>
            </head><body>`;
        const footer = "</body></html>";
        const sourceHTML = header + editorContent + footer;

        var source = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(sourceHTML);
        var fileDownload = document.createElement("a");
        document.body.appendChild(fileDownload);
        fileDownload.href = source;
        fileDownload.download = 'Document.doc';
        fileDownload.click();
        document.body.removeChild(fileDownload);
    }

    const convert_HTML_To_Pdf = () => {
            
        var options = {
            margin: 10,
            filename: 'Document.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
        };
        html2pdf().from(editorContent).set(options).save();
    }

    const convert_HTML_To_Txt = () => {
        var plainString = editorContent?.replace(/<[^>]+>/g, '');
        const element = document.createElement("a");
        document.body.appendChild(element); // Required for this to work in FireFox
        const file = new Blob([plainString], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "Document.txt";
        element.click();
        document.body.removeChild(element);
    }

    const modules = useMemo(() => ({
        toolbar: {
            container: toolbarOptions,
            handlers: {
                'source': () => {
                    const quill = quillRef.current?.getEditor();
                    toggle();
                    setEditorContent(quill.root.innerHTML);
                },
                'divider': () => {
                    const quill = quillRef.current?.getEditor();
                    const range = quill.getSelection();
                    if (range) {
                        quill.insertEmbed(range.index, 'divider', true);
                    }
                },
                'undo': () => {
                    const quill = quillRef.current?.getEditor();
                    if (quill?.history) {
                        quill.history.undo();
                    }
                },
                'redo': () => {
                    const quill = quillRef.current?.getEditor();
                    if (quill?.history) {
                        quill.history.redo();
                    }
                }
            }
        },
        history: {
            delay: 1000,
            maxStack: 100,
            userOnly: true
        }
    }), [toolbarOptions,quillRef]);

    const editor = useMemo(() => <ReactQuill
        ref={quillRef}
        theme="snow"
        value={secondEditorContent}
        modules={modules}
        formats={formats}
        placeholder="Your humanized text will show here"
        onChange={(content) => setEditorContent(content)}
        style={{ maxHeight: '485px', minHeight: '485px', }}
    />, [secondEditorContent])

    return (
        <Box>
            <Box>
                {editor}
                <SourceModal
                    open={modalOpen}
                    toggle={toggle}
                    handleSave={handleModalSave}
                    content={editorContent}
                    setEditorContent={setEditorContent}
                />
            </Box>
            <Box sx={{
                position: "absolute",
                bottom: "0",
                right: "0px",
                display: "flex"
            }}>
                {
                    !copyStatus ? (
                        <CopyToClipboard text={copyContent} onCopy={onCopyText}>
                            <IconButton aria-label="send" className=' editor-send-btn editor-send-btn-2' >
                                <img src={paste} alt="paste" />
                            </IconButton>
                        </CopyToClipboard>
                    ) : (
                        <Tooltip title="copied!" arrow open={true} placement="top">
                            <IconButton aria-label="send" className='editor-send-btn editor-send-btn-2' >
                                <img src={paste} alt="paste" />
                            </IconButton>
                        </Tooltip>
                    )
                }
                <Box className='editor-send-btn plus-icon editor-send-btn-new'>
                    {
                        <Box className='edit-green-box'>
                            <Box className='shape-image'></Box>
                        </Box>
                    }
                    <StyledSpeedDial
                        ariaLabel="SpeedDial playground example"
                        icon={<img src={plus} alt="paste" />}
                        className="editor-expan-show"
                        onClick={(event) => {
                            const imgAlt = event?.target?.alt;
                            const btnAriaLabel = event?.target?.ariaLabel;
                            if (imgAlt === "word" || btnAriaLabel === "word File") {
                                convert_HTML_To_Word();
                            }
                            if (imgAlt === "pdf" || btnAriaLabel === "PDF File") {
                                convert_HTML_To_Pdf();
                            }
                            if (imgAlt === "notepad" || btnAriaLabel === "Notepad File") {
                                convert_HTML_To_Txt();
                            }
                        }}
                    >
                        {actions.map((action) => (
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                            />
                        ))}
                    </StyledSpeedDial>
                </Box>
            </Box>
        </Box>
    );
}

export default AutoAIEditorSection